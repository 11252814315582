
.p-picklist-item:not(.cdk-drag-disabled) {
    cursor: move;
    z-index: 1130 !important;
    .p-tag{
        background: rgba(38, 169, 224, 0.32);
        border-radius: 15px;
        width: 120px;
        height: 30px;
    }
    .p-tag-icon, .p-tag-value, .p-tag-icon.pi{
        color: #000000;
        font-weight: 400;
    }

    .p-chip {
        border-radius: 25px;
        width: 60px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-chip-icon,
    .p-chip-text,
    .p-chip-icon.pi {
        color: #ffffff;
        font-weight: 400;
    }
    
}

.p-orderlist-item:not(.cdk-drag-disabled) {
    cursor: move;
    z-index: 1130 !important;
    .p-tag{
        background: rgba(38, 169, 224, 0.32);
        border-radius: 15px;
        width: 120x;
        height: 30px;
    }

    .p-tag-icon, .p-tag-value, .p-tag-icon.pi{
        color: #000000;
        font-weight: 400;
    }
    .p-chip {
        border-radius: 25px;
        width: 60px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-chip-icon,
    .p-chip-text,
    .p-chip-icon.pi {
        color: #ffffff;
        font-weight: 400;
    }
    .p-checkbox .p-checkbox-box {
        border: 1px solid #cbd5e1;
        width: 17px;
        height: 17px;
        border-radius: 12px;
}
}
