$icons: ('question-weight-icon',
);

$sizes: (
  'xs': (width: 14px, height: 14px),
  'sm': (width: 16px, height: 16px),
  'sm1': (width: 18px, height: 18px),
  'smd1': (width: 20px, height: 20px),
  'smd2': (width: 22px, height: 22px),
  'md': (width: 24px, height: 24px),
  'mlg': (width: 30px, height: 30px),
  'lg': (width: 36px, height: 36px),
  'xl': (width: 48px, height: 48px),
);

$defaultWidth: 24px;
$defaultHeight: 24px;

@each $icon in $icons {
  .ndigital-#{$icon} {
    background: url('../../../vinter/icons/#{$icon}.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: $defaultWidth;
    height: $defaultHeight;
  }
}

@each $size, $dimensions in $sizes {
  $width: map-get($dimensions, width);
  $height: map-get($dimensions, height);

  .ndigital-size-#{$size} {
    width: $width;
    height: $height;
  }
}
