.layout-topbar {
    width: 100%;
    margin-bottom: 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FCFCFC;
    box-shadow: 0px 0px 103px 0px rgba(0, 0, 0, 0.09);
    height: 3.8rem;

    .topbar-start {
        display: flex;
        align-items: center;

        .topbar-menubutton {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 2rem;
            border-radius: 50%;
            width: 2.5rem;
            height: 2.5rem;
            flex-shrink: 0;
            transition: background-color var(--transition-duration);

            i {
                font-size: 1.25rem;
                color: var(--text-color);
                transition: color var(--transition-duration);
            }

            &:hover {
                background-color: var(--primary-color);

                i {
                    color: var(--primary-color-text);
                }
            }
        }
    }

    .topbar-menu {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            &.topbar-profile {
                // margin-left: 1.5rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;


                button {
                    border-radius: 50%;
                    transition: box-shadow var(--transition-duration);
                    width: 100%;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            &.topbar-search {
                .p-inputtext {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    border-radius: 2rem;
                }
            }

            &.topbar-notification {
                .notification-badge .p-badge{
                    min-width: 1rem;
                    height: 1rem;
                    right: -2px;
                    top: -2px;
                    position: absolute;
                }
                .green-notification-badge .p-badge{
                    min-width: 1rem;
                    height: 1rem;
                }
                .custom-max-width{
                    max-width: 80%;
                }
                .green-background{
                    background-color: #EBFFEB;
                }
                .p-overlay-content {
                    max-width: 500px;
                    width: 500px;
                }
            }
        }
    }
}
.p-button.p-button-text:enabled:hover {
    background: transparent !important;
}
i{
    margin-right: 0.5rem;
}
.responsibleAvatar .p-avatar {
    height: 3rem;
    width: 3rem;
    font-size: 1.3rem;
    font-weight: 600;
    background-color: #CFDBF9 !important;
    color: #002995 !important;
}
.log-out-icon{
    color: var(--danger-main, #F20505);
    font-weight: 500;
}
.topbar-profile-text{
    color: var(--gray-700, #363D47);
    font-weight: 500;
}
.topbar-card .p-overlay{
    min-width: 155px !important;
    top: 59px !important;
    left: 0% !important;
    justify-content: end;
    display: flex;
    right:2%;
}
