.pageTitle {
    color: var(--gray-800, #1D2530);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.44px;
    min-height: 70px;
}

.subTitleText {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    color: var(--surface-600);
}

.contentsText {
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    color: var(--surface-500);
}

.buttonFont{
    font-size: 1rem !important;
}


.pageTitleLogin {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    color: var(--gray-800, #1D2530);
}

.subTitleTextLogin {
    font-size: 1rem !important;
    font-weight: 400 !important;
    color: var(--gray-300, #9B9EA3);
}

.contentsTextLogin {
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    color: var(--surface-500);
}

.buttonFontLogin{
    color: var(--White, #FFF) !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    font-style: normal !important;
}
