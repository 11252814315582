.layout-colorscheme-menu {
    --logo-color: var(--primary-color);
    --menu-bg: var(--gradient, linear-gradient(183deg, #152450 -0.11%, #0C2444 47.96%, #081931 99.13%));
    --root-menuitem-text-color: var(--primary-color);
    --menuitem-text-color: var(--text-color);
    --menuitem-icon-color: var(--primary-color);
    --menuitem-hover-bg-color: var(--surface-hover);
    --popup-submenu-item-text-color: var(--text-color);
    --popup-submenu-item-icon-color: var(--primary-color);
    --popup-submenu-item-hover-bg-color: var(--surface-hover);
}