/* Inter-regular - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('../../fonts/Inter-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Inter-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* Inter-500 - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('../../fonts/Inter-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Inter-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* Inter-600 - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('../../fonts/Inter-SemiBold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Inter-SemiBold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* Inter-700 - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('../../fonts/Inter-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Inter-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
    /* Nunito-200 - latin */
@font-face {
     font-family: 'Nunito';
     font-style: normal;
     font-weight: 200;
     src: local(''),
          url('../../fonts/Nunito-Light.ttf')
}
    /* Nunito-400 - latin */
@font-face {
     font-family: 'Nunito';
     font-style: normal;
     font-weight: 400;
     src: local(''),
          url('../../fonts/Nunito-Regular.ttf')
}
    /* Nunito-500 - latin */
@font-face {
     font-family: 'Nunito';
     font-style: normal;
     font-weight: 500;
     src: local(''),
          url('../../fonts/Nunito-Medium.ttf')
}
    /* Nunito-600 - latin */
@font-face {
     font-family: 'Nunito';
     font-style: normal;
     font-weight: 600;
     src: local(''),
          url('../../fonts/Nunito-SemiBold.ttf')
}
    /* Nunito-700 - latin */
@font-face {
     font-family: 'Nunito';
     font-style: normal;
     font-weight: 700;
     src: local(''),
          url('../../fonts/Nunito-Bold.ttf')
}
