$transitionDuration:.2s;

.layout-footer {
    transition: margin-left $transitionDuration;
    align-items: center;
    justify-content: center;
    padding-top: 0.3rem;
    border-top: 1px solid var(--surface-border);
    position: fixed;
    left: 25%;
    right: 0;
    bottom: 0%;
    height: 50px;
    display: flex;
}
