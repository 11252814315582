* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $scale;
}

body {
    font-weight: 400;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background: var(--surface-ground);
    color: var(--text-color);
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

.layout-container {
    min-height: 100vh;
}
.linkedin-icon {
    background-image: url("../../../vinter/icons/linkedin.svg");
    height: 2.3rem;
    width: 2.3rem;
    background-size: 2.3rem;
}
.github-icon {
    background-image: url("../../../vinter/icons/github.svg");
    height: 2.3rem;
    width: 2.3rem;
    background-size: 2.3rem;
}
.behance-icon {
    background-image: url("../../../vinter/icons/behance.svg");
    height: 2.3rem;
    width: 2.3rem;
    background-size: 2.3rem;
}
.dribble-icon {
    background-image: url("../../../vinter/icons/dribble.svg");
    height: 2.3rem;
    width: 2.3rem;
    background-size: 2.3rem;
}
.medium-icon {
    background-image: url("../../../vinter/icons/medium.svg");
    height: 2.3rem;
    width: 2.3rem;
    background-size: 2.3rem;
}
